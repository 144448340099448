import axios from "axios";
import { padZero } from "../../utils/utils";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiGetOrderHistoryList = (search, technician) => {
  const data = {
    textSearch: search,
    technicianEmail: technician === "All" ? "" : technician,
  };
  return axios
    .get(`/api/SalesOrder/order-history-odoo`, { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((data) => {
            if (data.dateOrder !== null) {
              var dateObject = new Date(data.dateOrder);

              // Format the date
              var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
                dateObject.getMonth() + 1
              )}/${dateObject.getFullYear().toString().slice(2)}`;
              var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
              var formattedTime = `${padZero(hours)}:${padZero(
                dateObject.getMinutes()
              )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
              // Combine date and time
              data.formattedDate = `${formattedDate}, ${formattedTime}`;
            }
          });
          return result.data;
        }
      }
      return false;
    });
};

export const apiGetOrderDetails = (id) => {
  const data = {
    orderId: id,
  };
  return axios
    .get(`/api/SalesOrder/order-details-odoo`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiGetStockProducts = (id, textSearch = "", pageNumber = 1) => {
  const data = {
    stockLocationId: id,
    PageNo: pageNumber,
    SearchParam: textSearch,
    Limit: 10,
  };
  return axios
    .get(`/api/InventoryManagement/get-products`, { params: data })
    .then((result) => {
      if (result && result.data) {
        const data = result.data;
        data.records = JSON.parse(data.records);
        console.log(data.records);
        data.records.map((item) => {
          item.quantity = 1;
        });
        return data;
      }
      return false;
    });
};

export const apiGetProductById = (id) => {
  return axios.get(`/api/Products/${id}`).then((result) => {
    if (result) {
      if (result.data) {
        const item = result.data;
        const data = {
          id: item.id,
          pack: item.uom && item.uom.name,
          stockCode: item.barcode,
          stockDescription: item.name,
          pricingPolicy: {
            price: item.listPrice,
            currencyId: item.currency.id,
          },
          productUom: item.uom.id,
          taxId: item.taxId,
        };
        return data;
      }
    }
    return false;
  });
};
