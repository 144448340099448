import CreateAccount from "../Auth/CreateAccount";
import Login from "../Auth/Login";
import Home from "../Home";
import Search from "../Search";
import ForgotPass from "../Auth/ForgotPassword";
import VerifyEmail from "../Auth/VerifyEmail";
import HoseMaker from "../HoseMaker";
import KitchenSink from "../KitchenSink";
import ResetPassword from "../Auth/ResetPassword";
import Profile from "../Profile";
import CafFrom from "../CafForm";
import DeliveryAddress from "../Profile/DeliveryAddress";
import ChangePassword from "../Profile/ChangePassword";
import VerifyNewEmail from "../Auth/VerifyNewEmail";
import Cart from "../Cart";
import Chat from "../Chat";
//import NewGroup from "../Chat/Chatpanel/AddGroup/NewGroup";
import OrderConfirmation from "../OrderConfirmation";
import PaymentResult from "../OrderConfirmation/PaymentResult";
import OrderHistory from "../OrderHistory";
import MyFavorites from "../MyFavorites";
import Asset from "../Asset";
import ManageAsset from "../AssetForm";
import AssetLocation from "../AssetLocation";
import Crew from "../CrewManagement";
import ServerErrorPage from "../../components/ErrorPages/ServerErrorPage";
import Inventory from "../Inventory";
import Stock from "../StockForm";
import StockReview from "../StockReview";
import InventoryLocation from "../InventoryLocation";
import Schedule from "../Schedule";
import AddMaintenanceForm from "../MaintenanceForm";
import MaintenanceForm from "../Maintenance";
import AuditTrail from "../AuditTrail";
import AssetDetail from "../AssetDetails";
import ReviewMaintenance from "../ReviewMaintenanceForm";
import CheckReview from "../ReviewMaintenanceForm/ShowPreview";
import * as path from "../../constants/routes";
import MyHoses from "../MyHoses";
import HoseMechanic from "../HoseMechanic";
import HomeBarChart from "../../components/HomeBarChart";
import PickUpAddress from "../../components/PickUpAddress";
import Jobbing from "../../containers/Jobbing";
import HoseMakerNew from "../../containers/HoseMakerNew";
import Settings from "../../containers/Settings";
import CreatePassword from "../Auth/CreatePassword";
import BOApod from "../BOApod";
import CompanyRanking from "../CompanyRanking";
import MyAssets from "../MyAssets";
import CallbackComponent from "../Callback/callback";
import OrderHistoryNew from "../OrderHistoryNew";
import HoseMap from "../HoseMap";
import MyResources from "../MyResources";
import BOAHIS from "../BOAhis";
import InventoryManagement from "../InventoryManagement";

export const UnAuthenticatedRoutes = [
  {
    name: "createAccount",
    component: CreateAccount,
    path: path.CREATE_ACCOUNT,
  },
  {
    name: "login",
    component: Login,
    path: path.LOGIN,
  },
  {
    name: "forgotPassword",
    component: ForgotPass,
    path: path.FORGOT_PASSWORD,
  },
  {
    name: "verifyEmail",
    component: VerifyEmail,
    path: path.VERIFY_EMAIL,
  },
  {
    name: "resetPassword",
    component: ResetPassword,
    path: path.RESET_PASSWORD,
  },
  {
    name: "createPassword",
    component: CreatePassword,
    path: path.CREATE_PASSWORD,
  },
  {
    name: "verifyNewEmail",
    component: VerifyNewEmail,
    path: path.VERIFY_NEW_EMAIL,
  },
  {
    name: "errorPage",
    component: ServerErrorPage,
    path: path.SERVER_ERROR_PAGE,
  },
  {
    name: "callback",
    component: CallbackComponent,
    path: path.CALLBACK,
  },
];

export const AuthenticatedRoutes = [
  {
    name: "home",
    component: Home,
    path: path.HOME,
  },
  {
    name: "savingsGraph",
    component: HomeBarChart,
    path: path.SAVING_GRAPH,
  },
  {
    name: "search",
    component: Search,
    path: path.SEARCH,
  },
  {
    name: "profile",
    component: Profile,
    path: path.PROFILE,
  },
  {
    name: "hoseMaker",
    component: HoseMakerNew,
    path: path.HOSE_MAKER,
  },
  {
    name: "hoseMaker",
    component: HoseMakerNew,
    path: path.HOSE_MAKER2,
  },
  {
    name: "kitchenSink",
    component: KitchenSink,
    path: path.KITCHEN_SINK,
  },
  {
    name: "creditApplicationForm",
    component: CafFrom,
    path: path.APPLICATION_FORM,
  },
  {
    name: "deliveryAddreses",
    component: DeliveryAddress,
    path: path.DELIVERY_ADDRESS,
  },

  {
    name: "changePassword",
    component: ChangePassword,
    path: path.CHANGE_ADDRESS,
  },

  {
    name: "cart",
    component: Cart,
    path: path.CART,
  },
  {
    name: "chat",
    component: Chat,
    path: path.CHAT,
  },

  // {
  //   name: "newgroup",
  //   component: NewGroup,
  //   path: path.NEW_GROUP
  // },

  {
    name: "orderConfirmation",
    component: OrderConfirmation,
    path: path.ORDER_CONFIRMATION,
  },

  {
    name: "paymentResult",
    component: PaymentResult,
    path: path.PAYMENT_RESULT,
  },

  {
    name: "orderHistory",
    component: OrderHistory,
    path: path.ORDER_HISTORY,
  },

  {
    name: "mostOrdered",
    component: MyFavorites,
    path: path.MY_FAVORITES,
  },
  {
    name: "asset",
    component: Asset,
    path: path.ASSET,
    isFacilities: true,
  },
  {
    name: "manageAsset",
    component: ManageAsset,
    path: path.MANAGE_ASSET,
    isFacilities: true,
  },
  {
    name: "assetLocation",
    component: AssetLocation,
    path: path.ASSET_LOCATION,
    isFacilities: true,
  },
  {
    name: "crew",
    component: Crew,
    path: path.CREW,
    isFacilities: true,
  },
  {
    name: "errorPage",
    component: ServerErrorPage,
    path: path.SERVER_ERROR_PAGE,
  },
  {
    name: "inventory",
    component: Inventory,
    path: path.INVENTORY,
    isFacilities: true,
  },
  {
    name: "stock",
    component: Stock,
    path: path.STOCK_FORM,
    isFacilities: true,
  },
  {
    name: "stockReview",
    component: StockReview,
    path: path.STOCK_REVIEW,
    isFacilities: true,
  },
  {
    name: "inventoryLocation",
    component: InventoryLocation,
    path: path.INVENTORY_LOCATION,
    isFacilities: true,
  },
  {
    name: "schedule",
    component: Schedule,
    path: path.SCHEDULE_MAINTENANCE,
    isFacilities: true,
  },
  {
    name: "addMaintenanceForm",
    component: AddMaintenanceForm,
    path: path.ADD_MAINTENANCE_FORM,
    isFacilities: true,
  },
  {
    name: "maintenanceForm",
    component: MaintenanceForm,
    path: path.MAINTENANCE_FORM,
    isFacilities: true,
  },
  {
    name: "addMaintenanceForm",
    component: AddMaintenanceForm,
    path: path.EDIT_MAINTENANCE_FORM,
    isFacilities: true,
  },
  {
    name: "auditTrail",
    component: AuditTrail,
    path: path.AUDIT_TRAIL,
    isFacilities: true,
  },
  {
    name: "reviewMaintenance",
    component: ReviewMaintenance,
    path: path.REVIEW_MAINTENANCE,
    isFacilities: true,
  },
  {
    name: "checkReview",
    component: CheckReview,
    path: path.CHECK_REVIEW,
    isFacilities: true,
  },
  {
    name: "auditDetail",
    component: AssetDetail,
    path: path.ASSET_DETAILS,
    isFacilities: true,
  },
  {
    name: "myHoses",
    component: MyHoses,
    path: path.MY_HOSES,
  },
  {
    name: "hoseMechanic",
    component: HoseMechanic,
    path: path.HOSE_MECHANIC,
    isFacilities: true,
  },
  {
    name: "verifyNewEmail",
    component: VerifyNewEmail,
    path: path.VERIFY_NEW_EMAIL,
  },
  {
    name: "pickUpAddress",
    component: PickUpAddress,
    path: path.PICKUP_ADDRESS,
  },
  {
    name: "jobbing",
    component: Jobbing,
    path: path.JOBBING,
  },
  {
    name: "settings",
    component: Settings,
    path: path.SETTINGS,
  },
  {
    name: "find-boapod",
    component: BOApod,
    path: path.BOAPOD,
  },
  // {
  //   name: "company-ranking",
  //   component: CompanyRanking,
  //   path: path.COMPANYRANKING,
  // },
  {
    name: "my-assets",
    component: MyAssets,
    path: path.MYASSETS,
  },
  {
    name: "callback",
    component: CallbackComponent,
    path: path.CALLBACK,
  },
  {
    name: "order-history",
    component: OrderHistoryNew,
    path: path.ORDER_HISTORY_NEW,
  },
  {
    name: "hose-map",
    component: HoseMap,
    path: path.HOSEMAP,
  },
  {
    name: "my-resources",
    component: MyResources,
    path: path.MY_RESOURCES,
  },
  {
    name: "boa-his",
    component: BOAHIS,
    path: path.BOA_HIS,
  },
  {
    name: "inventory-management",
    component: InventoryManagement,
    path: path.INVENTORY_MANAGEMENT,
  },
];
